<template>
  <a-card :bordered="false" class="card-area afterSale">

    <div class="data-area">
      <statistics-data-item
        title="故障总数"
        :value="statistics.totalNum" :content="statistics.totalDeviceNum" bgColor="#F6F9FC" color="#0084ff" :icon="require('../../../../static/img/ic_device_fault_total@3x.png')" />
      <statistics-data-item
        hidden
        title="已处理故障数"
        :value="statistics.dealNum" bgColor="#F3FFF8" color="#0BD169" :icon="require('../../../../static/img/ic_device_fault_processed@3x.png')" />
      <statistics-data-item
        title="今日新增故障"
        :value="statistics.todayNum" bgColor="#FFFAF5" color="#FFB764" :icon="require('../../../../static/img/ic_device_fault_today@3x.png')" />
      <statistics-data-item
        title="近7天新增故障"
        :value="statistics.sevenDayNum" bgColor="#FFF3F3" color="#FF7B7B" :icon="require('../../../../static/img/ic_device_fault_7@3x.png')" />
    </div>


    <common-table
      ref="table"
      path="deviceAlarm"
      :columns="columns"
      search-placeholder="搜索设备/设备ID/故障信息"
      :date-range="true" >

      <template slot="deal" slot-scope="{record}">
        <a-dropdown :trigger="['click']" v-if="record.deal==0">
          <a-menu slot="overlay" @click="handleIsDealClick(record)">
            <a-menu-item key="1">已处理</a-menu-item>
          </a-menu>
          <a-button type="danger" size="small">待处理 <a-icon type="down"></a-icon></a-button>
        </a-dropdown>
        <a-button v-else type="primary" ghost disabled size="small">已处理 </a-button>
      </template>
      <template slot="action" slot-scope="{record}">
        <action-view @click="view(record)"></action-view>
        <!--          <action-delete @click="view(record)"></action-delete>-->
      </template>

    </common-table>


  </a-card>
</template>
<script>
import StatisticsDataItem from '@/components/common/StatisticsDataItem'
export default {
  components:{
    StatisticsDataItem
  },
  data () {
    return {
      statistics: {
        totalNum: '-',
        dealNum: '-',
        todayNum: '-',
        sevenDayNum: '-'
      },

      status: [
        {
          value: 0,
          label: '所有状态'
        },
        {
          value: 1,
          label: '在线'
        },
        {
          value: 3,
          label: '离线'
        }
      ],
    }
  },
  created () {

    this.$get('deviceAlarm/statistics', {}).then((r) => {
      let data = r.data
      this.statistics = data
    })
  },
  computed: {
    columns () {
      return [
        {
          title: '设备',
          dataIndex: 'nickName'
        },
        {
          title: '故障时间',
          dataIndex: 'createdAt',
          sorter: true,
        },
        {
          title: '设备ID',
          dataIndex: 'deviceName'
        },
        {
          title: '故障信息',
          dataIndex: 'type'
        },
        // {
        //   title: '处理',
        //   scopedSlots: {customRender: 'deal'}
        // },
        {
          title: '操作',
          scopedSlots: {customRender: 'action'}
        }
      ]
    }
  },
  methods: {

    view (record) {
      this.$router.push(`/device/info/${record.iotId}`)
    },

    handleIsDealClick (record) {
      let that = this
      this.$confirm({
        title: '确定设置为已处理？',
        content: '当您点击确定按钮后，当前故障将变为已处理并无法重置',
        centered: true,
        onOk () {
          that.$put('deviceAlarm/deal', {
            id: record.id,
            isDeal: 1
          }).then(() => {
            that.$message.success('处理成功')
            that.getList()
          })
        }
      })
    },

    getList(){
      this.$refs.table.getData()
      this.$get('deviceAlarm/statistics', {}).then((r) => {
        let data = r.data
        this.statistics = data
      })
    }
  }
}
</script>
<style lang="less" scoped>

  .data-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

</style>
